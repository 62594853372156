export const environment = {
  production: false,
  apiURL: 'https://apiecommerce.demonow.tech/api/V1/AP/',//Testing
  EmpapiURL: 'https://apiecommerce.demonow.tech/api/V1/AP/',//Testing
  // apiURL: 'https://api.nrinfotechworld.com/api/V1/AP/',//Production
  // EmpapiURL: 'https://api.nrinfotechworld.com/api/V1/AP/',//Production
  firebaseConfig: {
    apiKey: "AIzaSyCqC8aUDVAWLbrAij4KCxK3gc0-sKkqLpI",
    authDomain: "nr-info.firebaseapp.com",
    projectId: "nr-info",
    storageBucket: "nr-info.appspot.com",
    messagingSenderId: "220293232300",
    appId: "1:220293232300:web:bf3d120c32138432dc9109",
    measurementId: "G-7K3Z26LQDT"
  },
};
